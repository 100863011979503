import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

const enterpriseSignUp = document.getElementById('microservice-enterprise-signup');
const enterpriseSignUpForDemo = document.getElementById('microservice-enterprise-register-for-demo');
const vendorSignUp = document.getElementById('microservice-vendor-signup');
const pricingOnVendor = document.getElementById('microservice-pricing-on-vendor');
const tablePlanOnPricing = document.getElementById('microservice-table-plan-on-pricing');

import EnterpriseSignup from './pages/embed/EnterpriseSignup';
import EnterpriseRequestDemo from './pages/embed/EnterpriseSignup';
import PricingOnVendor from './pages/embed/EnterpriseSignup';
import TablePlanOnPricing from './pages/embed/EnterpriseSignup';
import VendorSignUp from './pages/embed/EnterpriseSignup';

window.renderIframeMicroService = () => {
  // Ignore if in admin WordPress
  if (location.href.includes('/wp-admin/')) {
    return;
  }

  if (enterpriseSignUp) {
    ReactDOM.createRoot(enterpriseSignUp).render(<EnterpriseSignup />);
  }

  if (enterpriseSignUpForDemo) {
    ReactDOM.createRoot(enterpriseSignUpForDemo).render(<EnterpriseRequestDemo />);
  }

  if (pricingOnVendor) {
    ReactDOM.createRoot(pricingOnVendor).render(<PricingOnVendor />);
  }

  if (tablePlanOnPricing) {
    ReactDOM.createRoot(tablePlanOnPricing).render(<TablePlanOnPricing />);
  }

  if (vendorSignUp) {
    ReactDOM.createRoot(vendorSignUp).render(<VendorSignUp />);
  }
};

window.renderIframeMicroService();
